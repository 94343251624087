<template>
  <div class="mag-dec">
    <div class="section1">
      <h1>我们的优势</h1>
      <div class="advantage">
        <div class="item" v-for="(advantage, idx) in dataAarr1" :key="idx">
          <div class="img-wrapper"><img :src="advantage.icon" alt="" /></div>
          <p>{{ advantage.des[0] }}</p>
          <p>{{ advantage.des[1] }}</p>
        </div>
      </div>
    </div>
    <div class="section2">
      <h1>以公平公正，数据真实性为核心的“互联网+物流+政府服务”监管服务平台</h1>
      <div class="data2">
        <div class="item" v-for="(item, idx) in dataArr2" :key="idx">
          <div class="img-wrapper"><img :src="item.img" alt="" /></div>
          <p>{{ item.des }}</p>
        </div>
      </div>
    </div>
    <div class="section3">
      <h1>目前已上线的监管平台数据</h1>
      <div class="data3">
        <div class="item" v-for="(item, idx) in dataArr3" :key="idx">
          <h2>{{ item.num }}</h2>
          <p>{{ item.name }}</p>
        </div>
      </div>
      <div class="leftandri">
        <img src="../../assets/img/product_img.png" alt="" />
        <div class="ri-text">
          <p>
            2020年6月16日，陕西省副省长赵刚一行，在榆林市副市长张胜利、绥德县县长姬跃飞等人的陪同下，考察调研平台运行情况，对平台表
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "magdec",
  data() {
    return {
      dataAarr1: [
        {
          icon: require("@/assets/img/product_icon1.png"),
          des: ["131项", "数据元素采集"]
        },
        {
          icon: require("@/assets/img/product_icon2.png"),
          des: ["10项", "重点要素监管"]
        },
        {
          icon: require("@/assets/img/product_icon3.png"),
          des: ["40项", "校验维度"]
        },
        {
          icon: require("@/assets/img/product_icon1.png"),
          des: ["多方", "数据佐证"]
        }
      ],
      dataArr2: [
        {
          img: require("@/assets/img/product_img1.png"),
          des:
            "采用独立第三方的多维度验证、与国家网络货运监管平台、地方货物平台交叉比对四流、三节点数据，确保数据真实性"
        },
        {
          img: require("@/assets/img/product_img2.png"),
          des:
            "建立合同、运单、资金流水、税票的四流上游数据体系，货主、司机、车辆的三节点下游数据体系，无缝覆盖网络货运业务"
        },
        {
          img: require("@/assets/img/product_img3.png"),
          des:
            "实时上报数据，实时异常监控，配合实时大数据大屏，创新监管，促进行业公平竞争，为政府高效服务企业，营造便利环境，综合推进网络货运"
        }
      ],
      dataArr3: [
        { num: "30+", name: "对接网络货运企业" },
        { num: "1500万+", name: "已验证运单" },
        { num: "180亿+", name: "已验证税票" }
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.mag-dec {
  .section1 {
    background: url(~@/assets/img/product_bg1.png);
    background-position: center;
    height: 570px;
    width: 1200px;
    margin: 0 auto;
    h1 {
      text-align: center;
      height: 29px;
      font-size: 30px;
      font-family: Heiti SC;
      font-weight: bold;
      color: #666666;
      line-height: 26px;
      padding-top: 64px;
      padding-bottom: 140px;
    }
    .advantage {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-wrapper {
          width: 126px;
          height: 126px;
          background: #a1d7fd;
          opacity: 0.48;
          border-radius: 50%;
          text-align: center;
          img {
            margin-top: 36px;
          }
          margin-bottom: 26px;
        }
        p {
          font-size: 30px;
          font-family: Heiti SC;
          font-weight: bold;
          color: #666666;
        }
      }
    }
  }

  .section2 {
    background: url(~@/assets/img/product_bg2.png);
    background-position: center;
    height: 641px;
    width: 100%;
    margin: 0 auto;
    h1 {
      text-align: center;
      height: 31px;
      font-size: 30px;
      font-family: Adobe Heiti Std;
      font-weight: bold;
      color: #666;
      line-height: 26px;
      padding-top: 64px;
      padding-bottom: 101px;
    }
    .data2 {
      width: 1200px;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .item {
        width: 266px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .img-wrapper {
          width: 266px;
          height: 222px;
          background: #ecf7ff;
          border-radius: 10px;
          text-align: center;
          margin-bottom: 26px;
          overflow: hidden;
        }
        p {
          width: 240px;
          height: 140px;
          font-size: 20px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          color: #999;
          line-height: 30px;
        }
      }
    }
  }

  .section3 {
    background: url(~@/assets/img/product_bg3.png);
    background-position: center;
    height: 915px;
    width: 100%;
    h1 {
      text-align: center;
      height: 29px;
      font-size: 30px;
      font-family: Heiti SC;
      font-weight: bold;
      color: #666666;
      line-height: 26px;
      padding-top: 63px;
      padding-bottom: 110px;
    }
    .data3 {
      width: 1200px;
      display: flex;
      justify-content: space-around;
      margin: 0 auto;
      .item {
        width: 266px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h2 {
          font-size: 56px;
          font-family: Lantinghei SC;
          font-weight: bold;
          color: #666666;
        }
        p {
          width: 225px;
          height: 26px;
          font-size: 26px;
          font-family: Heiti SC;
          font-weight: bold;
          color: #666666;
          line-height: 26px;
          margin-top: 32px;
          text-align: center;
        }
      }
    }
    .leftandri {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      padding-top: 78px;
      padding-left: 65px;
      .ri-text {
        width: 499px;
        height: 305px;
        background: #eeeeee;
        margin-left: -31px;
        margin-top: 31px;
        padding: 51px 54px;
        p {
          width: 401px;
          height: 207px;
          font-size: 28px;
          font-family: Heiti SC;
          font-weight: bold;
          color: #3e3e3e;
          line-height: 36px;
        }
      }
    }
  }
}
</style>
